import React, { useState } from "react";
import "./CustomerTicketRequest.css";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../components/firebase";
import Select from "react-select";

function CustomerTicketRequest() {
  const [step, setStep] = useState(1);
  const [departureFromCity, setDepartureFromCity] = useState("");
  const [departureToCity, setDepartureToCity] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [tripType, setTripType] = useState("one-way");
  const [ticketCounts, setTicketCounts] = useState({
    adult: 1,
    child: 0,
    infant: 0,
  });
  const [customerDetails, setCustomerDetails] = useState([]);
  const [seatPreference, setSeatPreference] = useState("");
  const [mealPreference, setMealPreference] = useState("");
  const [comments, setComments] = useState("");

  const handleNext = () => {
    const totalTickets = Object.values(ticketCounts).reduce((a, b) => a + b, 0);
    setCustomerDetails(Array(totalTickets).fill({ name: "", email: "" }));
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "ticketRequests"), {
        departureFromCity,
        departureToCity,
        departureDate,
        returnDate: tripType === "return" ? returnDate : null,
        tripType,
        ticketCounts,
        customerDetails,
        seatPreference,
        mealPreference,
        comments,
      });
      alert("Ticket request submitted successfully!");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const cityOptions = [
    { value: "new_york", label: "New York" },
    { value: "london", label: "London" },
    { value: "paris", label: "Paris" },
    { value: "tokyo", label: "Tokyo" },
    { value: "dubai", label: "Dubai" },
    { value: "sydney", label: "Sydney" },
    { value: "toronto", label: "Toronto" },
    { value: "hong_kong", label: "Hong Kong" },
    { value: "singapore", label: "Singapore" },
    { value: "los_angeles", label: "Los Angeles" },
  ];

  return (
    <div className="ticket-request-form">
      <h2>Customer Ticket Request</h2>
      {step === 1 ? (
        <form onSubmit={(e) => e.preventDefault()}>
          {/* Departure and Arrival Section */}
          <div className="grid-container">
            <label>
              Departure From:
              <Select
                options={cityOptions}
                value={cityOptions.find((opt) => opt.value === departureFromCity)}
                onChange={(opt) => setDepartureFromCity(opt.value)}
                placeholder="Select city"
              />
            </label>
            <label>
              Departure To:
              <Select
                options={cityOptions}
                value={cityOptions.find((opt) => opt.value === departureToCity)}
                onChange={(opt) => setDepartureToCity(opt.value)}
                placeholder="Select city"
              />
            </label>
          </div>

          {/* Trip Type and Date */}
          <label>
            Trip Type:
            <select
              value={tripType}
              onChange={(e) => setTripType(e.target.value)}
              required
            >
              <option value="one-way">One Way</option>
              <option value="return">Return</option>
            </select>
          </label>
          <label>
            Departure Date:
            <input
              type="date"
              value={departureDate}
              onChange={(e) => setDepartureDate(e.target.value)}
              required
            />
          </label>
          {tripType === "return" && (
            <label>
              Return Date:
              <input
                type="date"
                value={returnDate}
                onChange={(e) => setReturnDate(e.target.value)}
                required
              />
            </label>
          )}

          {/* Ticket Type */}
          <label>
            Ticket Type:
            <div className="ticket-types">
              <label>
                Adults:
                <input
                  type="number"
                  min="0"
                  value={ticketCounts.adult}
                  onChange={(e) =>
                    setTicketCounts({ ...ticketCounts, adult: parseInt(e.target.value) })
                  }
                  required
                />
              </label>
              <label>
                Children:
                <input
                  type="number"
                  min="0"
                  value={ticketCounts.child}
                  onChange={(e) =>
                    setTicketCounts({ ...ticketCounts, child: parseInt(e.target.value) })
                  }
                />
              </label>
              <label>
                Infants:
                <input
                  type="number"
                  min="0"
                  value={ticketCounts.infant}
                  onChange={(e) =>
                    setTicketCounts({ ...ticketCounts, infant: parseInt(e.target.value) })
                  }
                />
              </label>
            </div>
          </label>
          <button className="next-button" type="button" onClick={handleNext}>
            Next
          </button>
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          {customerDetails.map((_, index) => (
            <div className="customer-detail" key={index}>
              <label>
                Customer {index + 1} Name:
                <input
                  type="text"
                  value={customerDetails[index]?.name || ""}
                  onChange={(e) => {
                    const updatedDetails = [...customerDetails];
                    updatedDetails[index].name = e.target.value;
                    setCustomerDetails(updatedDetails);
                  }}
                  required
                />
              </label>
              <label>
                Customer {index + 1} Email:
                <input
                  type="email"
                  value={customerDetails[index]?.email || ""}
                  onChange={(e) => {
                    const updatedDetails = [...customerDetails];
                    updatedDetails[index].email = e.target.value;
                    setCustomerDetails(updatedDetails);
                  }}
                  required
                />
              </label>
            </div>
          ))}
          <label>
            Seat Preference:
            <select
              value={seatPreference}
              onChange={(e) => setSeatPreference(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="window">Window</option>
              <option value="aisle">Aisle</option>
            </select>
          </label>
          <label>
            Meal Preference:
            <div className="meal-preference-options">
              <label>
                <input
                  type="radio"
                  value="vegetarian"
                  checked={mealPreference === "vegetarian"}
                  onChange={() => setMealPreference("vegetarian")}
                />
                Vegetarian
              </label>
              <label>
                <input
                  type="radio"
                  value="non-vegetarian"
                  checked={mealPreference === "non-vegetarian"}
                  onChange={() => setMealPreference("non-vegetarian")}
                />
                Non-Vegetarian
              </label>
            </div>
          </label>
          <label>
            Comments:
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </label>
          <button className="back-button" type="button" onClick={handleBack}>
            Back
          </button>
          <button className="submit-button" type="submit">
            Submit
          </button>
        </form>
      )}
    </div>
  );
}

export default CustomerTicketRequest;
